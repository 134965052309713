/*
Раздел для ветеринарных врачей.
Ваканции, обучающие статьи и направления на обследования.
Авторизация не нужна.

*/

<template>
	<div>
		<!-- <meta http-equiv = "refresh" content = "1 ; url = http://lk.vetlight.ru/about/contracts.html"/> -->
		<template v-if="post_id">
			<!-- Показываем, если передали Post_id -->
			<span v-html="post_info.content"></span>
		</template>
		<template v-else>
			<!-- Если post_id отсутствует - выодим список -->
			<h2>Список документов для Врачей</h2>
			<v-list two-line>
				<v-list-item-group v-model="selected" active-class="primary">
					<template v-for="(item, index) in post_list">
						<v-list-item :href="getRoute(item.id)" :key="item.title">
							<template v-slot:default>
								<v-list-item-content>
									{{ item.title }}
								</v-list-item-content>
							</template>
						</v-list-item>
						<v-divider v-if="index < post_list.length - 1" :key="index"></v-divider>
					</template>
				</v-list-item-group>
			</v-list>
		</template>

	</div>
</template>

<script type="text/javascript">
	export default {
		name: 'ForDoctors',
		props: [ 'post_id',],
		data() {
			return {
				selected: null,
			}
		},
		methods: {
			getRoute(id){
				console.log('getRoute', id)
				this.$store.dispatch('uloadPostInfoAsync', {url:'/site/documents', id:id})
				return "/doctors/"+id
			},
			getPost(){
				console.log('getPost->start')
				this.$store.dispatch('uloadPostInfoAsync', {url:'/site/documents', id:this.post_id, query:this.$route.query})
				console.log('getPost->stop')
			},
		},
		computed: {
			post_list(){ return this.$store.getters.getPostList },
			post_info(){ return this.$store.getters.getPostInfo },
		},
		beforeMount() {
			if (this.post_id) {
				//console.log('post_id', this.$store.getters.getPostInfo(this.post_id))
				console.log('beforeMount->query', this.$route.query)
				//this.getPost()
				this.$store.dispatch('uloadPostInfoAsync', {url:'/site/documents', id:this.post_id, query:this.$route.query})
				console.log("DONE", this.post_info, ':')
				
			}
			this.$store.dispatch('uploadPostListAsync', '/site/documents')
		}
	}
</script>